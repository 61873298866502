import { mobileScreenWidth } from "./constants";
import Aos from "aos";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

function afterDOMContentLoaded() {
  Aos.init({
    disable: window.innerWidth < mobileScreenWidth,
    once: true,
    duration: 1000,
  });

  // smooth scroll local links into view
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });

  // Fucking write this god damned css to the head to fucking fix the sass transpiler piece of shit from shit-fucking
  // the mother fucking order of the transforms used in the header. Fuck you sass.
  const styleEl = document.createElement("style");
  if (window.innerWidth > mobileScreenWidth) {
    styleEl.textContent = `
    #page-header #hamburger-menu.open-menu .line:nth-child(1) { transform: translateY(-2.8px) rotate(45deg) scaleY(0.93) scaleX(1.84); }
    #page-header #hamburger-menu.open-menu .line:nth-child(3) { transform: translateY(2.8px) rotate(-45deg) scaleY(0.93) scaleX(1.84); }
    `;
  } else {
    styleEl.textContent = `
    #page-header #hamburger-menu.open-menu .line:nth-child(1) { transform: translateY(1px) rotate(45deg) scaleY(0.66) scaleX(1.31); }
    #page-header #hamburger-menu.open-menu .line:nth-child(3) { transform: translateY(-1px) rotate(-45deg) scaleY(0.66) scaleX(1.31); }
    `;
  }
  document.head.appendChild(styleEl);
}
